import React, { useContext, useEffect, useRef, useState } from "react";
import NotifyScreen from "./Component/notify";
import { useParams, useHistory } from "react-router-dom";
import { AgoraContext } from "../../Context/AgoraContext";
import AgoraRTC from "agora-rtc-sdk-ng";
import { socket } from "../utils/Client";
import EndPoints from "../utils/apiEndPoints";
import { apiCall } from "../utils/httpClient";
import { QuestionContext } from "../../Context/QuestionContext";
const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  facingMode: FACING_MODE_USER,
};
let rtc = {
  client: null,
  localAudioTrack: null,
  localVideoTrack: null,
};
var video;
const NotifyView = () => {
  let { audit_id } = useParams();
  const history = useHistory();
  const [questionn, setquestionlist] = useState();
  const [online, setonline] = useState(false);
  const [qty, setqty] = useState();
  var [intervalId, setintervalId] = useState("");
  const [auditStart, setAuditStart] = useState(false);
  const [imgurl, setimgurl] = useState("");
  const [requestData, setrequestData] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [token, settoken, channel, setchannel] = useContext(AgoraContext);
  const [rating, setRating] = useState(0);
  const [remark, setremark] = useState("");
  const [front, setFront] = useState(true);
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
  const [camToggle, setcamToggle] = useState(true);
  const camToggleRef = useRef(true);
  const [micToggle, setmicToggle] = useState(true);
  const [bmplay, setbmplay] = useState(1);
  const [rmmplay, setrmmplay] = useState(1);
  const [actionablescore, setactionablescore] = useState(0);
  const [questionList, setactionalequestionList] = useState([]);
  const [totalScrore, settotalScore] = useState(0);
  const [question, setquestion] = useContext(QuestionContext);
  const options = {
    appId: "b13f7540466747e6a102327255673a59",
    channel: channel,
    token: token,
  };
  async function startBasicCall() {
    rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    const uid = await rtc.client.join(
      options.appId,
      options.channel,
      options.token,
      null
    );
    rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    var d = await rtc.client.publish([
      rtc.localAudioTrack,
      rtc.localVideoTrack,
    ]);
    const remoteVideoTrack = rtc.localVideoTrack;
    const playerContainer = document.getElementById("player");
    playerContainer.style.width = "100%";
    playerContainer.style.height = "480px";
    remoteVideoTrack.play(playerContainer);
    rtc.client.on("user-published", async (user, mediaType) => {
      // Subscribe to a remote user.
      await rtc.client.subscribe(user, mediaType);
      setonline(true);
      if (mediaType === "video") {
        const remoteVideoTrack = user.videoTrack;
        const playerContainer = document.createElement("div");
        playerContainer.id = user.uid.toString();
        playerContainer.style.width = "0px";
        playerContainer.style.height = "0px";
        document.body.append(playerContainer);
        remoteVideoTrack.play(playerContainer);
      }
      if (mediaType === "audio") {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
      }
    });
  }
  
  async function leaveCall() {
    if (rtc.localAudioTrack != null || rtc.localVideoTrack != null) {
      rtc.localAudioTrack.close();
      rtc.localVideoTrack.close();
      await rtc.client.leave();
    }
  }
  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);
  useEffect(() => {
    startBasicCall();
    return () => {
      leaveCall();
    };
  }, []);

  useEffect(() => {
    socket.on("checker", (data) => {
      if (data.socketEvent == `checker${audit_id}`) {
        setImagePreview(null);
      }
    });
    socket.on("bm_online", (data) => {
      if (data.socketEvent == `pauseOnlineAudit${audit_id}`) {
        setbmplay(data.data.bm_online);
      }
    });
    socket.on("rating_sent", (data) => {
      if (data.audit_id == audit_id) {
        setRating(data.val);
      }
    });
    socket.on("remark_sent", (data) => {
      if (data.audit_id == audit_id) {
        setremark(data.val);
      }
    });
    socket.on("qtyview_sent", (data) => {
      if (data.audit_id == audit_id) {
        setqty(data.qty);
      }
    });
    socket.on("startcalling", (data) => {
      if (data.socketEvent == `getQuestionList${audit_id}`) {
        if (data.status == 200) {
          setquestionlist(data.data);
          setimgurl(data.base_url);
          setAuditStart(true);
          setRating(0);
          setremark("");
          setqty("");
          setactionablescore(0);
        } else {
          window.location.assign(`/actionable/${audit_id}`);
        }
      }
    });
    socket.on("completeQuestionAudit", (data) => {
      if (data.audit_id == audit_id) {
        //window.location.assign(`/actionable/${audit_id}`);
        setactionablescore(1);
        handleActionable(audit_id);
      }
    });
    socket.on("getpreviousquestion", (data) => {
      if (data.socketEvent == `previousquestion${audit_id}`) {
        setquestionlist(data.data);
        setimgurl(data.base_url);
        setAuditStart(true);
        setRating(data.answer.score_range);
        setremark(data.answer.remark);
      }
    });
    socket.on("captureImageAccept", (data, callback) => {
      if (data.socketEvent == `captureImageAccept${audit_id}`) {
        handleUploadimage();
      }
    });
    socket.on("deleteCaptureReponse", (data, callback) => {
      if (data.status == 200) {
        if (data.socketEvent == `deleteCaptureImg${audit_id}`) {
          setimgurl(data.base_url);
          setImagePreview(data?.data);
        }
      }
    });
  }, []);
  const handleUploadimage = async () => {
    const params = { audit_id: audit_id };
    socket.emit("getImagedata", params, (data) => {
      if (data.socketEvent == `getImagedata${audit_id}`) {
        uploadIMGDataSocket(data);
      }
    });
  };
  const uploadIMGDataSocket = (data) => {
    if (data.status === 200) {
      if (data?.data[0]?.status === 0) {
        setrequestData(data.data[0]);
        captuepic(data.data[0].id, data.data[0].question_id);
      }
    }
  };

  const captuepic = async (id, question_id) => {
    const player = document.getElementById("player1");
    const imageWidth = document.getElementById("player1").offsetWidth;
    const imageHeight = document.getElementById("player1").offsetHeight;
    const outputCanvas = document.getElementById("output");
    const context = outputCanvas.getContext("2d");
    outputCanvas.width = imageWidth;
    outputCanvas.height = imageHeight;
    
    if (navigator.platform !== "iPhone") {
      await navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: camToggleRef.current === true ? "user" : "environment",
          },
          audio: false,
        })
        .then((stream) => {
          player.srcObject = stream;

          setTimeout(() => {
            context.drawImage(player, 0, 0, imageWidth, imageHeight);
            outputCanvas.toBlob((blob) => {
              //   var url = URL.createObjectURL(blob);
              var reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function () {
                var base64data = reader.result;
                handleCaptureImage(base64data, id, question_id);
              };
            });
          }, 400);
          setTimeout(() => {
            
            stopnewCapture(stream);
          }, 450);
        })
        .catch((error) => {
          alert(error);
        });
    } else {

      await navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: camToggleRef.current === true ? "user" : "environment",
          },
          audio: false,
        })
        .then((stream) => {
          player.srcObject = stream;

          setTimeout(() => {
            context.drawImage(player, 0, 0, imageWidth, imageHeight);
            outputCanvas.toBlob((blob) => {
              //   var url = URL.createObjectURL(blob);
              var reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function () {
                var base64data = reader.result;
                handleCaptureImage(base64data, id, question_id);
              };
            });
          }, 400);
          setTimeout(() => {
            leaveCall()
            setmicToggle(true)
            setcamToggle(true)
            camToggleRef.current=true
            stopnewCapture(stream);
            
              setTimeout(() => {
                startBasicCall()
              }, 500);
            }, 550);
        })
        .catch((error) => {
          alert(error);
        });
    }

    // }, 600);
  };

  const handleCaptureImage = async (blob, id, question_id) => {
    const params = {
      audit_id: audit_id,
      question_id: question_id,
      id: id,
      image_data: blob,
    };
    // alert(JSON.stringify(params));
    await socket.emit("updateCaptureimage", params, (data) => {
      // alert("data");
      if (data.status == 200) {
        // alert("updateCaptureimage")
        if (data.socketEvent == `updateCaptureimage${audit_id}${question_id}`) {
          setimgurl(data.base_url);
          setImagePreview(data?.image_data);
          setrequestData(data.data);
        }
      }
    });
  };
  const toggleCam = (state) => {
    AgoraRTC.getDevices().then((devices) => {
      const videoDevices = devices.filter(function (device) {
        return device.kind === "videoinput";
      });
      if (videoDevices.length > 1) {
        if (state) {
          rtc.localVideoTrack
            .setDevice(videoDevices[0].deviceId)
            .then(() => {})
            .catch((e) => {
              alert(e);
            });
        } else {
          rtc.localVideoTrack
            .setDevice(videoDevices[videoDevices.length - 1].deviceId)
            .then(() => {})
            .catch((e) => {
              alert(e);
            });
        }
      } else {
        console.log("No such device to toggle");
      }
    });
  };
  const toggleAudio = async (state) => {
    setmicToggle(!micToggle);
    rtc.localAudioTrack.setEnabled(state);
  };
  const stopnewCapture = async (stream) => {
    stream.getTracks().forEach(function (track) {
      track.stop();
    });
  };

  const setOnlineBM = (val) => {
    setbmplay(val);
    // rtc.localAudioTrack.setEnabled(val==0?false:true)
    // setmicToggle(val==0?false:true)
    var param = {
      type: 1,
      audit_id: audit_id,
      status: val,
    };
    socket.emit("pauseOnlineAudit", param, (data) => {
      if (data.socketEvent == `pauseOnlineAudit${audit_id}`) {
        setrmmplay(data.data.rmm_online);
        setbmplay(data.data.bm_online);
      }
    });
  };

  const handleActionable = async (audit_id) => {
    try {
      const params = { audit_id: audit_id, type: 1 };
      const { data } = await apiCall(
        "post",
        EndPoints.ACTIONABLEDETAIL,
        params
      );
      if ((data.status = 200)) {
        setactionalequestionList(data.data);
        settotalScore(data.score);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleQuestion = (item) => {
    setquestion(item);
    window.location.assign(`/actionquestion`);
  };
  return (
    <>
      <NotifyScreen
        question={questionn}
        imgurl={imgurl}
        online={online}
        setonline={setonline}
        auditStart={auditStart}
        requestData={requestData}
        imagePreview={imagePreview}
        setrequestData={setrequestData}
        handleCaptureImage={handleCaptureImage}
        token={token}
        channel={channel}
        rating={rating}
        remark={remark}
        front={front}
        setFront={setFront}
        videoConstraints={videoConstraints}
        facingMode={facingMode}
        handleClick={handleClick}
        rtc={rtc}
        toggleCam={toggleCam}
        toggleAudio={toggleAudio}
        camToggle={camToggle}
        setcamToggle={setcamToggle}
        micToggle={micToggle}
        setmicToggle={setmicToggle}
        camToggleRef={camToggleRef}
        qty={qty}
        bmplay={bmplay}
        setbmplay={setbmplay}
        setOnlineBM={setOnlineBM}
        handleActionable={handleActionable}
        handleQuestion={handleQuestion}
        actionablescore={actionablescore}
        questionList={questionList}
        totalScrore={totalScrore}
      />
    </>
  );
};

export default NotifyView;
